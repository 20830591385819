import { Grid } from '@mui/material'
import { FC } from 'react'
import CustomAutoComplete from './custom_auto_complete'

interface ICycleInputProps {
   onChange: (o: any) => void
   value: string | number | undefined | any
   error?: string
   isFormDirty?: boolean
   required?: boolean
   cycles: any[]
}
const CycleInput: FC<ICycleInputProps> = ({ value, onChange, error, isFormDirty, cycles }) => {
   return (
      <Grid item xs={2} key='cycle'>
         <CustomAutoComplete
            name='cycle'
            options={cycles}
            label='Cycle Name'
            required
            value={value}
            onChange={onChange}
            error={error}
            isFormDirty={isFormDirty}
         />
      </Grid>
   )
}

export default CycleInput
