import DashboardIcon from '@mui/icons-material/Dashboard'
import JoinInnerIcon from '@mui/icons-material/JoinInner'
import { CardContent, Typography } from '@mui/material'
import { IconMenu, IconMenuProps, MenuItemList, MenuItemNode } from '@react-admin/ra-navigation'

export const AppMenu = (props: IconMenuProps) => (
   <IconMenu {...props}>
      <IconMenu.Item name='dashboard' to='/' end label='Dashboard' icon={<DashboardIcon />} />
      {/* The empty filter is required to avoid falling back to the previously set filter */}
      <IconMenu.Item name='dataintegrators' label='Data integrators' icon={<DashboardIcon />}>
         <CardContent>
            <Typography variant='h6' gutterBottom>
               Data integrators
            </Typography>
            <MenuItemList>
               {/* The empty filter is required to avoid falling back to the previously set filter */}
               <MenuItemNode name='FileProcess' to={'/vscrfileprocess?filter={}'} label='File Process' />
               <MenuItemNode name='Trigger' to={'/jobqueue'} label='Trigger' />
               <MenuItemNode name='Daily Activity' to={'/getdailyactivityl1?filter={}'} label='Daily Activity' />
            </MenuItemList>
         </CardContent>
      </IconMenu.Item>
   </IconMenu>
)
