import { Resource } from 'react-admin'
// import { Resource } from '@react-admin/ra-rbac'
import { Admin } from '@react-admin/ra-enterprise'
import { AuthProvider, DataProvider } from './services'
import { Login } from './views/login'
import { vfsfileprocess } from './views/fileprocess'
import { Dashboard } from './views/dashboard'
import { Layout } from './components/layout'
import { lightTheme, darkTheme } from './components/layout/themes'
import { jobQueue } from './views/jobqueue'
import { dailyActivity } from './views/dailyActivity'
import { dailyActivity2 } from './views/dailyActivity2'

export const App = () => {
   return (
      <Admin
         loginPage={Login}
         authProvider={AuthProvider()}
         dataProvider={DataProvider()}
         dashboard={Dashboard}
         layout={Layout}
         disableTelemetry
         requireAuth
         lightTheme={lightTheme}
         darkTheme={darkTheme}
      >
         <Resource name='vscrfileprocess' {...vfsfileprocess} />
         <Resource name='jobqueue' {...jobQueue} />
         <Resource name='getdailyactivityl1' {...dailyActivity} />
         <Resource name='getdailyactivityl2' {...dailyActivity2} />
      </Admin>
   )
}
export default App
