import { Grid, FormHelperText } from '@mui/material'
import { FC } from 'react'
import CustomAutoComplete from './custom_auto_complete'
import { IFormState } from './type'

interface IApplicationInputs {
   onChange: (o: any) => void
   error?: string
   isFormDirty?: boolean
   required?: boolean
   applications: any[]
   providers: any[]
   services: any[]
   formdata: IFormState
}

const ApplicationInputs: FC<IApplicationInputs> = ({
   applications,
   providers,
   services,
   formdata,
   onChange,
   isFormDirty,
}) => {
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   /* @ts-ignore */
   const values = ['application', 'service', 'provider'].map((o) => Object.values(formdata[o] ?? {}).join()).join('')

   return (
      <Grid item xs={6} key='application_wrapper'>
         <Grid container spacing={2}>
            <Grid item xs={6} key='application'>
               <CustomAutoComplete
                  name='application'
                  value={formdata['application']}
                  options={applications}
                  label='Application Name'
                  onChange={onChange}
               />
            </Grid>
            {/*    <Grid item xs={4} key='service'>
               <CustomAutoComplete
                  name='service'
                  value={formdata['service']}
                  options={services}
                  label='Service Name'
                  onChange={onChange}
               />
            </Grid> */}
            <Grid item xs={6} key='provider'>
               <CustomAutoComplete
                  name='provider'
                  value={formdata['provider']}
                  options={providers}
                  label='Provider Name'
                  onChange={onChange}
               />
            </Grid>
            {isFormDirty && !values && (
               <FormHelperText sx={{ padding: '0 16px' }} error>
                  {/* {applicationInputError} */}
                  Either field is required
               </FormHelperText>
            )}
         </Grid>
      </Grid>
   )
}

export default ApplicationInputs
