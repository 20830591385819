import { useCanAccess, List } from '@react-admin/ra-rbac'
import { EditableDatagrid, EditableDatagridProps, RowForm } from '@react-admin/ra-editable-datagrid'
import { useNavigate, useLocation } from 'react-router-dom'
import {
   BooleanField,
   ReferenceField,
   TextField,
   BooleanInput,
   DateInput,
   ReferenceInput,
   SelectInput,
   TextInput,
   required,
   FunctionField,
   useListContext,
   Identifier,
   Button,
   Link,
} from 'react-admin'
import { Modal, Tooltip } from '@mui/material'
import Aside from './Aside'
import Dialog, { DialogProps } from './Dialog'
import React, { useState } from 'react'
import { setElSeg } from '@fullcalendar/common'
import Audlog from './Audlog'

const TextFieldWithTitle = () => {
   const { data } = useListContext()
   return <TextField label='File Name' source='file_name' title={'title is here'} />
}

const getDateObj = () => {
   const currentDate = new Date()
   const day = currentDate.getDate()
   const month = currentDate.getMonth() + 1

   const year = currentDate.getFullYear()
   return {
      day,
      month,
      year,
   }
}
const { month, year } = getDateObj()
const convertStringToNumber = (value: string) => {
   const int = parseInt(value)
   return isNaN(int) ? null : int
}
const postFilters = [
   <TextInput resettable key={0} label='Search by File key' source='file_key' alwaysOn />,

   <SelectInput
      resettable
      alwaysOn
      key={0}
      label='Status'
      source='cy_status_descr'
      optionText={'name'}
      optionValue={'value'}
      choices={[
         { name: 'Cleansed', value: 'Cleansed' },
         { name: 'Cleansing Error', value: 'Cleansing Error' },
         { name: 'Failed', value: 'Failed' },
         { name: 'Hold', value: 'Hold' },
         { name: 'Imported', value: 'Imported' },
         { name: 'New', value: 'New' },
         { name: 'PUSHED TO CLIENT', value: 'PUSHED TO CLIENT' },
         { name: 'Re-Cleanse', value: 'Re-Cleanse' },
         { name: 'Restated', value: 'Restated' },
      ]}
   />,
   <TextInput
      resettable
      key={0}
      label='Search by Provider id'
      type='number'
      parse={convertStringToNumber}
      source='provider_id'
      alwaysOn
   />,
   <DateInput key={0} label='Search by Update Date' source='update_date' alwaysOn />,
   <DateInput key={0} label='Search by Create Date' source='create_date' alwaysOn />,
   <TextInput key={0} label='Search by Service desc' source='service_descr' />,
   <TextInput key={0} label='Search by Technology' source='technology_descr' />,
   <TextInput key={0} label='Search by Component id' source='component_id' />,
   <TextInput key={0} label='Search by Component desc' source='component_descr' />,
   <TextInput
      key={0}
      label='Search by Period id '
      source='period_id'
      // defaultValue={[`${year}${month}`, `${year}${month - 1}`]}
   />,
   <SelectInput
      key={0}
      label='Active'
      source='active_flag'
      optionText={'name'}
      optionValue={'value'}
      choices={[
         { name: 'True', value: true },
         { name: 'False', value: false },
      ]}
   />,
   <SelectInput
      key={0}
      label='Service'
      source='service_id'
      optionText={'name'}
      optionValue={'value'}
      choices={[
         { name: 'Daily', value: 20 },
         { name: 'Retail Scan', value: 29 },
         { name: 'NABCA ', value: 10 },
         { name: 'Tdlinx', value: [18, 19] },
         { name: 'Canada ', value: [31, 12, 14] },
         { name: 'Canada ACD ', value: 12 },
         { name: 'Canada LCB ', value: 14 },
         { name: 'Canada Weekly LCB', value: 31 },
         { name: 'Master Data', value: [13, 16] },
         { name: 'Monthly ', value: [4, 1] },
         { name: 'Not Daily', value: [4, 12, 13, 14, 18, 21, 25, 29, 31, 32, 33] },
      ]}
   />,
]

type DialogState = Omit<DialogProps, 'handleClose'>

const DEFAULT_DIALOG_STATE = {
   open: false,
   record: {},
   id: '',
   resource: '',
}

const DEFAULT_LOG_STATE = {
   open: false,
   fileKey: '',
}
export const Vfsfileprocess = () => {
   const { state } = useLocation()
   const navigate = useNavigate()
   const { filter = {} } = state || {}
   const [dialogProps, setDialogProps] = useState<DialogState>(DEFAULT_DIALOG_STATE)
   const [logState, setLogState] = useState(DEFAULT_LOG_STATE)
   const onRowClick = (id: Identifier, resource: string, record: any) => {
      setDialogProps({ open: true, id, resource, record } as unknown as DialogState)
      return ''
   }
   const handleClose = () => {
      setDialogProps(DEFAULT_DIALOG_STATE)
   }

   const toggleLogDialogState = (fileKey?: any) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()

      setLogState({
         open: !logState.open,
         fileKey,
      })
   }

   const onClick = () => {
      navigate('/getdailyactivityl2', {
         state: {
            filter: {
               PROVIDER_ID: filter.provider_id,
               CREATE_DATE: filter.create_date,
            },
         },
      })
   }
   return (
      <>
         {state && (
            <Button
               label='Go back'
               to='/getdailyactivityl2'
               onClick={onClick}
               style={{ justifyContent: 'left', marginTop: 10 }}
            />
         )}
         <List
            disableSyncWithLocation
            sx={{
               '.filter-field': {
                  alignSelf: 'center',
               },
            }}
            hasCreate
            empty={false}
            exporter={false}
            filters={postFilters}
            filterDefaultValues={filter}
            // aside={<Aside />}
            // sort={{ field: 'period_id', order: 'DESC' }}
            sort={{ field: 'file_key', order: 'DESC' }}
         >
            <EditableDatagrid
               bulkActionButtons={false}
               createForm={VfsRowForm()}
               editForm={VfsRowForm()}
               actions={<></>}
               rowClick={onRowClick}
            >
               <FunctionField
                  label='Log'
                  render={(record: any) => (
                     <Button label='log' color='primary' onClick={toggleLogDialogState(record.file_key)} />
                  )}
               />
               <TextField source='technology_descr' />
               <TextField source='file_key' />
               <FunctionField
                  label='Cy Status'
                  render={(record: any) => `${record.cy_status_id} - ( ${record.cy_status_descr})`}
               />

               <TextField source='period_id' />
               <TextField source='import_table' />
               <FunctionField
                  label='Provider'
                  render={(record: any) => `${record.provider_id} - ( ${record.provider_descr})`}
               />

               <FunctionField
                  label='Provider Service Component'
                  render={(record: any) =>
                     `${record.provider_service_component_id} - ( ${record.provider_service_component_descr})`
                  }
               />

               <FunctionField
                  label='Source group Desc'
                  render={(record: any) => `${record.pv_src_group_id} - ( ${record.pv_src_group_descr})`}
               />
               <FunctionField
                  label='File name'
                  render={(record: any) => (
                     <span title={`File path: '${record.filepath}' \nDir result: '${record.dir_results}'`}>
                        {record.file_name}
                     </span>
                  )}
               />
               {/* <TextField source='filepath' />
               <TextField source='dir_results' /> */}
               <TextField source='create_date' />
               <TextField source='update_date' />
               <TextField source='update_user_id' />
               <TextField source='comment' />
               {/* <TextField source='service_id' />
            <TextField source='service_descr' />
            <TextField source='component_id' />
            <TextField source='component_descr' /> */}
               <BooleanField source='active_flag' />
            </EditableDatagrid>
         </List>
         {dialogProps.open && <Dialog handleClose={handleClose} {...dialogProps} />}
         {logState.open && <Audlog fileKey={logState.fileKey} toggleDialog={toggleLogDialogState} />}
      </>
   )
}

const VfsRowForm = () => {
   return (
      // eslint-disable-next-line camelcase
      <RowForm>
         <TextInput source='file_key' autoCapitalize='true' validate={required()} />
         <TextField source='cy_status_descr' />
         <TextField source='period_id' />
         <TextField source='import_table' />
         <TextInput source='provider_id' autoCapitalize='true' validate={required()} />
         <TextField source='provider_descr' />
         <TextField source='provider_service_component_id' />
         <TextField source='provider_service_component_descr' />
         <TextField source='pv_src_group_id' />
         <TextField source='pv_src_group_descr' />
         <TextField source='file_name' />
         <TextField source='filepath' />
         <TextField source='dir_results' />
         <TextField source='create_date' />
         <TextField source='update_date' />
         <TextField source='update_user_id' />
         <TextField source='comment' />
         <TextField source='service_id' />
         <TextField source='service_descr' />
         <TextField source='component_id' />
         <TextField source='component_descr' />
         <BooleanField source='active_flag' />
         <TextField source='file_process_row_id' />
      </RowForm>
   )
}
