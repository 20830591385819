/* eslint-disable camelcase */
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid'
import { FC } from 'react'
import { Button, FunctionField, Identifier, Link, List, TextField } from 'react-admin'
import { useNavigate, useLocation } from 'react-router-dom'

const CustomButton: FC<{ label: string; record: any; filter?: any }> = ({ label = '', record, filter = {} }) => {
   const navigate = useNavigate()

   const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      navigate('/vscrfileprocess', {
         state: {
            filter: {
               provider_id: record.PROVIDER_ID,
               create_date: record.CREATE_DATE,
               component_descr: record.COMPONENT_DESCR,
               ...filter,
            },
         },
      })
   }
   return <Button label={label.toString()} onClick={onClick} color={parseInt(label) > 0 ? 'primary' : 'inherit'} />
}

export const DailyActivity2 = () => {
   const { state } = useLocation()
   const navigate = useNavigate()
   const { filter = {} } = state || {}

   const onRowClick = (id: Identifier, resource: string, record: any) => {
      navigate('/vscrfileprocess', {
         state: {
            filter: {
               provider_id: record.PROVIDER_ID,
               create_date: record.CREATE_DATE,
               component_descr: record.COMPONENT_DESCR,
            },
         },
      })
      return ''
   }

   const onClick = (key: string, record: any) => (e: React.MouseEvent<HTMLButtonElement>) => {
      navigate('/vscrfileprocess', {
         state: {
            filter: {
               provider_id: record.PROVIDER_ID,
               create_date: record.CREATE_DATE,
               component_descr: record.COMPONENT_DESCR,
            },
         },
      })
   }
   return (
      <>
         <Button
            label='Go back'
            to='/getdailyactivityl1'
            component={Link}
            style={{ justifyContent: 'left', marginTop: 10 }}
         />
         <List filter={filter} sort={{ field: 'PROVIDER_ID', order: 'ASC' }}>
            <EditableDatagrid actions={<></>} editForm={<></>} rowClick={onRowClick}>
               <TextField source='APPLICATION' label='Application' />
               <TextField source='COMPONENT_DESCR' label='Componenet Desc' />
               <TextField source='CREATE_DATE' label='Create Date' />
               <TextField source='PROVIDER_ID' label='Provider Id' />
               <TextField source='TOTAL_FILES' label='Total Files' />
               {/* <TextField source='PUSHED_TO_CLIENT' label='Pushed To Client' /> */}
               <FunctionField
                  label='Pushed To Client'
                  render={(record: any) => (
                     <CustomButton
                        label={record.PUSHED_TO_CLIENT}
                        record={record}
                        filter={{ cy_status_descr: 'PUSHED TO CLIENT' }}
                     />
                  )}
               />
               {/* <TextField source='FILES_IN_PROCESS' label='Files In Process' /> */}
               <FunctionField
                  label='Files In Process'
                  render={(record: any) => (
                     <CustomButton
                        label={record.FILES_IN_PROCESS}
                        record={record}
                        filter={{ cy_status_descr: ['New', 'Re-Cleanse'] }}
                     />
                  )}
               />
               {/* <TextField source='READY_TO_PUSH' label='Ready To Push' /> */}
               <FunctionField
                  label='Ready To Push'
                  render={(record: any) => (
                     <CustomButton
                        label={record.READY_TO_PUSH}
                        record={record}
                        filter={{ cy_status_descr: 'Cleansed' }}
                     />
                  )}
               />
               {/* <TextField source='FAILED_TRANSFORM' label='Failed Transform' /> */}
               <FunctionField
                  label='Failed Transform'
                  render={(record: any) => (
                     <CustomButton
                        label={record.FAILED_TRANSFORM}
                        record={record}
                        filter={{ cy_status_descr: 'Cleansing Error' }}
                     />
                  )}
               />
               {/* <TextField source='ON_HOLD' label='On Hold' /> */}
               <FunctionField
                  label='ON HOLD'
                  render={(record: any) => (
                     <CustomButton label={record.ON_HOLD} record={record} filter={{ cy_status_descr: 'Hold' }} />
                  )}
               />
               <TextField source='TO_INGEST' label='To Ingest' />
               {/*  <TextField source='TO_TRANSFORM' label='To Transform' /> */}
               <FunctionField
                  label='To Transform'
                  render={(record: any) => (
                     <CustomButton
                        label={record.TO_TRANSFORM}
                        record={record}
                        filter={{ cy_status_descr: ['Imported', 'Re-Cleanse'] }}
                     />
                  )}
               />
               {/* <TextField source='FAILED' label='Failed' /> */}
               <FunctionField
                  label='Failed'
                  render={(record: any) => (
                     <CustomButton label={record.FAILED} record={record} filter={{ cy_status_descr: 'Failed' }} />
                  )}
               />
               <TextField source='PENDING' label='Pending' />
               <TextField source='FAILED_PUSH' label='Failed Push' />
               {/* <FunctionField
                  label='Pending'
                  render={(record: any) => (
                     <CustomButton
                        label={record.PENDING}
                        record={record}
                        filter={{ cy_status_descr: ['Cleansing Error', 'New', 'Re-Cleanse', 'Imported'] }}
                     />
                  )}
               /> */}
            </EditableDatagrid>
         </List>
      </>
   )
}
