import { defaultDarkTheme, defaultLightTheme } from '@react-admin/ra-enterprise'

export const theme = {}

export const darkTheme = {
   ...defaultDarkTheme,
   ...theme,
}

export const lightTheme = {
   ...defaultLightTheme,
   ...theme,

   palette: {
      primary: {
         main: '#3a01d8',
      },
   },

   // palette: {
   //    primary: {
   //       light: '#757ce8',
   //       main: '#3f50b5',
   //       dark: '#002884',
   //       contrastText: '#fff',
   //    },
   //    secondary: {
   //       light: '#ff7961',
   //       main: '#f44336',
   //       dark: '#ba000d',
   //       contrastText: '#000',
   //    },
   // },
}
