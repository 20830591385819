import { FormControl, Autocomplete, FormHelperText, TextField as MuiTextField } from '@mui/material'
import { FC } from 'react'

interface ICustomSelectProps {
   onChange: (o: any) => void
   name: string
   value: string | number | undefined | any
   label: string
   options: any[]
   error?: string
   isFormDirty?: boolean
   required?: boolean
}

const CustomAutoComplete: FC<ICustomSelectProps> = ({
   onChange,
   name,
   value,
   label,
   options,
   error,
   isFormDirty,
   required,
}) => {
   const getOptionLabel = (option: any) => {
      if (!option?.id) return ''
      return ['provider', 'cycle'].includes(name) ? `${option.id} - ${option.name}` : option.name
   }
   return (
      <FormControl fullWidth error={isFormDirty && !!error && required}>
         <Autocomplete
            renderInput={(params) => <MuiTextField {...params} label={label} />}
            options={options}
            onChange={(e, option: any) => {
               onChange({ name, value: option })
            }}
            getOptionLabel={getOptionLabel}
            value={value}
         />
         {isFormDirty && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
   )
}

export default CustomAutoComplete
