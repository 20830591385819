import { Card, CardContent, CardHeader } from '@mui/material'
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid'
import { List } from '@react-admin/ra-rbac'
import { TextField, SelectInput, TextInput, DateInput } from 'react-admin'
import CreateJob from './create_job'

const convertStringToNumber = (value: string) => {
   const int = parseInt(value)
   return isNaN(int) ? null : int
}
const postFilters = [
   <DateInput key={0} label='Search by Queue Date' source='QUEUE_ENTRY_DATE' alwaysOn />,
   <SelectInput
      resettable
      alwaysOn
      key={0}
      label='Active'
      source='ACTIVE_FLAG'
      optionText={'name'}
      optionValue={'value'}
      choices={[
         { name: 'True', value: true },
         { name: 'False', value: false },
      ]}
   />,
   <TextInput resettable key={0} label='Search by client' source='CLIENT_DESCR' alwaysOn />,
   <TextInput
      resettable
      key={0}
      label='Search by provider'
      type='number'
      parse={convertStringToNumber}
      source='PROVIDER_ID'
      alwaysOn
   />,

   <TextInput key={0} label='Search by Application' source='APPLICATION' alwaysOn />,

   <TextInput key={0} label='Search by status ' source='STATUS' />,
]

export const JobQueue = () => {
   return (
      <>
         <CreateJob />
         <Card sx={{ marginTop: '15px' }}>
            <CardHeader title='Job Queue' />
            <CardContent>
               <List filters={postFilters} sort={{ field: 'QUEUE_ENTRY_DATE', order: 'DESC' }}>
                  <EditableDatagrid actions={<></>} editForm={<></>}>
                     <TextField source='QUEUE_ENTRY_SOURCE' label='Source' />
                     <TextField source='QUEUE_ENTRY_DATE' label='Queue Date' />
                     <TextField source='QUEUE_PICKUP_DATE' label='Pickup Date' />
                     <TextField source='STATUS' />
                     <TextField source='BATCH_ID' label='Batch' />
                     <TextField source='CYCLE_DESC' label='Cycle' />
                     <TextField source='CLIENT_DESCR' label='Client' />
                     <TextField source='ACTIVE_FLAG' label='Active' />
                     <TextField source='HANDSHAKE_FLAG' label='Handshake' />
                     <TextField source='UPDATE_USER_ID' label='Updated By' />
                     <TextField source='PROVIDER_ID' label='Provider' />
                     <TextField source='APPLICATION' label='Application' />
                     <TextField source='SERVICE_ID' label='Service' />
                  </EditableDatagrid>
               </List>
            </CardContent>
         </Card>
      </>
   )
}
