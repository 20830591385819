import { Button, Card, CardHeader, CardContent, Grid } from '@mui/material'
import { useState } from 'react'
import { Form, useCreate, useGetIdentity, useNotify } from 'react-admin'
import ApplicationInputs from './application_inputs'
import CustomAutoComplete from './custom_auto_complete'
import CycleInput from './cycle_input'
import { IFormState } from './type'

import useFormOptions from './useFormOptions'

const DEFAULT_FORM_DATA = {
   queue: {},
   client: {},
   provider: {},
   application: {},
   service: {},
   cycle: {},
}

const CreateJob = () => {
   const [isFormDirty, setFormDirty] = useState(false)
   const { identity } = useGetIdentity()
   const { airFlow, services, clients, applications, providers, cycles } = useFormOptions()
   const [create] = useCreate()
   const [formdata, setFormData] = useState<IFormState>(DEFAULT_FORM_DATA)
   const isError = (v: any) => Object.values(v || {}).length < 1

   const onChange = ({ name, value }: { name: keyof IFormState; value: any }) => {
      setFormData({
         ...formdata,
         [name]: value,
      })
   }
   const notify = useNotify()
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   /* @ts-ignore */
   const values = ['application', 'service', 'provider'].map((o) => Object.values(formdata[o] ?? {}).join()).join('')

   const handleUpdate = () => {
      setFormDirty(true)
      const {
         service,
         /* eslint-disable camelcase*/
         client,
         provider,
         queue,
         application,
         cycle,
      } = formdata
      const { client_id: CLIENT_ID, client_descr: CLIENT_DESCR } = client || {}
      const { SERVICE_ID } = service || {}
      const { PROVIDER_ID } = provider || {}
      const { name } = queue || {}
      const { APPLICATION } = application || {}
      const { CYCLE_ID, CYCLE_DESCR: CYCLE_DESC } = cycle || {}

      let isError = !name || !CYCLE_ID

      if (name !== 'cycles_process') {
         isError = !name || !CLIENT_ID || !values
      }

      if (isError) return

      // "UPDATE_USER_ID": "rb-initiateCycle",
      const otherData = name === 'cycles_process' ? { CYCLE_ID, CYCLE_DESC } : { SERVICE_ID, PROVIDER_ID, APPLICATION }
      const data = {
         CLIENT_ID,
         CLIENT_DESCR,
         /* eslint-enable camelcase*/
         QUEUE_ENTRY_SOURCE: name,
         UPDATE_USER_ID: `initiate${name}-${identity?.fullName}`.replace('_process', ''),
         STATUS: 'WAITING',
         ACTIVE_FLAG: true,
         HANDSHAKE_FLAG: true,
         ...otherData,
      }

      create(
         'jobqueue',
         { data },
         {
            onSuccess: () => {
               setFormDirty(false)
               setFormData(DEFAULT_FORM_DATA)
               notify('Job Queue created successfully ', { undoable: true })
            },
         }
      )
   }

   return (
      <Card sx={{ marginTop: '15px' }}>
         <CardHeader title='Create Job' />
         <CardContent>
            <Form noValidate>
               <Grid container spacing={2} sx={{ marginTop: 0 }}>
                  <Grid item xs={2} key='queue'>
                     <CustomAutoComplete
                        name='queue'
                        value={formdata['queue']}
                        options={airFlow}
                        label='Trigger Name'
                        onChange={onChange}
                        error={isError(formdata['queue']) ? 'Required field' : ''}
                        isFormDirty={isFormDirty}
                        required
                     />
                  </Grid>
                  {formdata.queue?.name === 'cycles_process' ? (
                     <CycleInput
                        value={formdata['cycle']}
                        onChange={onChange}
                        isFormDirty={isFormDirty}
                        cycles={cycles}
                        error={isError(formdata['cycle']) ? 'Required field' : ''}
                     />
                  ) : null}
                  <Grid item xs={2} key='client'>
                     <CustomAutoComplete
                        name='client'
                        value={formdata['client']}
                        options={clients}
                        label='Client Name'
                        onChange={onChange}
                        error={
                           formdata.queue?.name !== 'cycles_process' && isError(formdata['client'])
                              ? 'Field is required'
                              : ''
                        }
                        isFormDirty={isFormDirty}
                        required
                     />
                  </Grid>

                  {formdata.queue?.name !== 'cycles_process' ? (
                     <ApplicationInputs
                        formdata={formdata}
                        isFormDirty={isFormDirty}
                        onChange={onChange}
                        services={services}
                        applications={applications}
                        providers={providers}
                     />
                  ) : null}

                  <Grid item xs={2} sx={{ alignSelf: 'center' }}>
                     <Button variant={'contained'} color={'primary'} onClick={handleUpdate} type='submit'>
                        Create Job
                     </Button>
                  </Grid>
               </Grid>
            </Form>
         </CardContent>
      </Card>
   )
}

export default CreateJob
