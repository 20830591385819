import { useGetList } from 'react-admin'

const useFormOptions =() => {
   const { data: airFlow = [] } = useGetList('airflowdagmeta', {
      sort: { field: 'name', order: 'DESC' },
      meta: { isPagination: false },
   })
   const { data: clients = [] } = useGetList('getclient', {
      sort: { field: 'client_descr', order: 'DESC' },
      meta: { isPagination: false },
   })
   const { data: services = [] } = useGetList('getservice', {
      sort: { field: 'SERVICE_DESCR', order: 'DESC' },
      meta: { isPagination: false },
   })
   const { data: providers = [] } = useGetList('getprovider', {
      sort: { field: 'PROVIDER_DESCR', order: 'DESC' },
      meta: { isPagination: false },
   })
   const { data: applications = [] } = useGetList('getapplication', {
      sort: { field: 'APPLICATION', order: 'DESC' },
      meta: { isPagination: false },
   })
   const { data: cycles = [] } = useGetList('getcycle', {
      sort: { field: 'name', order: 'DESC' },
      filter: { ACTIVE_FLAG: true },
      meta: { isPagination: false },
   })
   return {
      airFlow,
      clients,
      providers,
      applications,
      services,
      cycles
   }
}

export default useFormOptions;
