/* eslint-disable camelcase */
import { DialogContentText, DialogActions, Box, Chip } from '@mui/material'
import MuiDialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import MuiTextField from '@mui/material/TextField'
import { FC } from 'react'
// import { Datagrid, List } from '@react-admin/ra-rbac'
import {
   Button as RaButton,
   TextField,
   Datagrid,
   List,
   ListGuesser,
   useGetOne,
   useGetList,
   EditButton,
   useListContext,
} from 'react-admin'

export interface DialogProps {
   handleClose: () => void
   record: any
   id: string
   resource: string
   open: boolean
}

const FileProcessDatagrid = () => {
   const { data } = useListContext()
   if (!data) return <></>
   const columns = Object.keys(data[0])

   return (
      <Datagrid size='medium' bulkActionButtons={false}>
         {columns.map((col) => (
            <TextField source={col} key={col} label={`${col.substring(0, 8)}...`}></TextField>
         ))}
      </Datagrid>
   )
}

const FileProcessList = ({ tablename, technology_descr }: any) => {
   return (
      <List resource='vscrfileprocesspreview' queryOptions={{ meta: { tablename, technology_descr } }}>
         <FileProcessDatagrid />
      </List>
   )
}
const Dialog: FC<DialogProps> = ({ handleClose, open, record = {}, id, resource }) => {
   if (!record.import_table) return null
   const { data } = useGetList('vscrfileprocesscount', {
      meta: { tablename: record.import_table },
   })

   return (
      <MuiDialog open={open} onClose={handleClose} fullWidth maxWidth='xl'>
         <DialogTitle>{record.import_table}</DialogTitle>
         <DialogContent>
            <Box>
               <Chip label={'Preview:20'} />
               <span style={{ width: '1em', display: 'inline-block' }} />
               <Chip label={`Total: ${data ? data[0].total : ''}`} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <MuiTextField label='period id' variant='filled' />
               <span style={{ width: '1em', display: 'inline-block' }} />
               <Button variant='contained'>Update</Button>
            </Box>
            <FileProcessList tablename={record.import_table} technology_descr={record.technology_descr} />
         </DialogContent>
         <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
         </DialogActions>
      </MuiDialog>
   )
}

export default Dialog
