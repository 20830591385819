import { AuthProvider as RaAuthProvider } from 'react-admin'
import { loginRequest } from './AuthConfig'
import { useMsal } from '@azure/msal-react'

type Permissions = {
   action: string | string[]
   resource: string
   record?: { [key: string]: string }
   type?: string
}

type AzureAccessToken = {
   roles: string[]
} & {
   [key: string]: string
}

const rolesMapping: Record<string, Permissions[]> = {
   // READ
   Admin: [{ action: '*', resource: '*' }],
}

export const AuthProvider = (): RaAuthProvider => {
   const { instance } = useMsal()

   return {
      login: async () => {
         return instance.loginRedirect(loginRequest)
      },
      logout: async () => {
         const accounts = instance.getAllAccounts()
         if (accounts.length > 0) return instance.logoutRedirect()

         return Promise.resolve()
      },
      checkAuth: async () => {
         await instance.handleRedirectPromise()
         const accounts = instance.getAllAccounts()

         // Slow react-admin rendering to avoid flashing login page. Should be fix in 4.3 release
         if (accounts.length > 0) {
            instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] })
         }
         if (accounts.length === 0) {
            return Promise.reject({ message: false }) // Stops Please login error message from being displayed everytime
         }

         return Promise.resolve()
      },
      checkError: async (error) => {
         const status = error.status
         if (status === 401 || status === 403) return Promise.reject()

         // other error code (404, 500, etc): no need to log out
         return Promise.resolve()
      },
      getIdentity: async () => {
         await instance.handleRedirectPromise()
         const accounts = instance.getAllAccounts()

         // Slow react-admin rendering to avoid flashing login page. Should be fix in 4.3 release
         if (accounts.length > 0) {
            instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] })
         }

         if (accounts.length === 0) return Promise.reject()

         return Promise.resolve({
            id: accounts[0].localAccountId,
            fullName: accounts[0].name,
         })
      },
      getPermissions: async () => {
         return Promise.resolve()
      },
   }
}
