import { EditableDatagrid } from '@react-admin/ra-editable-datagrid'
import {
   FunctionField,
   Identifier,
   List,
   SelectInput,
   TextField,
   useCreatePath,
   TextInput,
   SelectArrayInput,
   useGetList,
   AutocompleteInput,
} from 'react-admin'
import { useNavigate } from 'react-router-dom'

const ProviderFilter = ({
   label,
   resettable,
   source,
   alwaysOn,
}: {
   label: string
   resettable: boolean
   source: string
   alwaysOn: boolean
}) => {
   const { data = [], isLoading } = useGetList('getprovider', {
      sort: { field: 'PROVIDER_DESCR', order: 'DESC' },
      meta: { isPagination: false },
   })

   const getOptionLabel = (option: any) => {
      if (!option?.id) return ''
      return `${option.id} - ${option.name}`
   }

   return (
      <AutocompleteInput
         choices={data}
         optionText={getOptionLabel}
         optionValue={'PROVIDER_ID'}
         // resettable={resettable}
         label={label}
         source={source}
         alwaysOn={alwaysOn}
         fullWidth={true}
      />
   )
}

const ApplicationFilter = ({
   label,
   resettable,
   source,
   alwaysOn,
}: {
   label: string
   resettable: boolean
   source: string
   alwaysOn: boolean
}) => {
   const { data = [], isLoading } = useGetList('getapplication', {
      sort: { field: 'APPLICATION', order: 'DESC' },
      meta: { isPagination: false },
   })

   const getOptionLabel = (option: any) => {
      if (!option?.id) return ''
      return `${option.id} - ${option.name}`
   }

   return (
      <AutocompleteInput
         choices={data}
         optionText='APPLICATION'
         optionValue='APPLICATION'
         // resettable={resettable}
         label={label}
         source={source}
         alwaysOn={alwaysOn}
      />
   )
}

const postFilters = [
   <SelectArrayInput
      key={0}
      alwaysOn
      // defaultValue={[...Array(7).keys()]}
      label='Days ago'
      source='DAYS_AGO_LABEL'
      optionText={'name'}
      optionValue={'value'}
      // resettable
      choices={[
         { name: 'Today', value: 'Today' },
         { name: 'Yesterday', value: 'Yesterday' },
         { name: 'Weekly', value: 'Weekly' },
         { name: 'Last Month', value: 'Monthly' },
         { name: 'Last Quarter ', value: 'Last Quarter' },
         { name: 'Last 6 Months', value: 'Last 6 Months' },
         {
            name: 'Last Year',
            value: 'Last Year',
         },
         { name: 'Rest', value: 'Rest' },
      ]}
   />,
   <ProviderFilter
      key={'provider_searcher'}
      resettable={true}
      source='PROVIDER_ID'
      alwaysOn={true}
      label='Search by Provider'
   />,
   <ApplicationFilter
      key={'application_searcher'}
      resettable={true}
      source='APPLICATION'
      alwaysOn={true}
      label='Search by Application'
   />,
   // <TextInput resettable key={0} label='Search by Application' source='APPLICATION' alwaysOn />,
]
export const DailyActivity = () => {
   const navigate = useNavigate()
   const onRowClick = (id: Identifier, resource: string, record: any) => {
      navigate('/getdailyactivityl2', {
         state: {
            filter: {
               PROVIDER_ID: record.PROVIDER_ID,
               APPLICATION: record.APPLICATION,
               CREATE_DATE: record.CREATE_DATE,
            },
         },
      })
      return ''
   }
   return (
      <List
         sx={{
            '& .RaFilterForm-filterFormInput:nth-child(2)': {
               width: 200,
            },
         }}
         filters={postFilters}
         sort={{ field: 'PROVIDER_ID', order: 'ASC' }}
      >
         <EditableDatagrid actions={<></>} editForm={<></>} rowClick={onRowClick}>
            <TextField source='APPLICATION' label='Application' />
            <FunctionField
               label='Provider'
               render={(record: any) => `${record.PROVIDER_ID} - ( ${record.PROVIDER_DESCR})`}
            />
            <TextField source='CREATE_DATE' label='Create Date' />
            <TextField source='TOTAL_FILES' label='Total Files' />
            <TextField source='PUSHED_TO_CLIENT' label='Pushed To Client' />
            <TextField source='FILES_IN_PROCESS' label='Files In Process' />
            <TextField source='READY_TO_PUSH' label='Ready To Push' />
            <TextField source='FAILED_TRANSFORM' label='Failed Transform' />
            <TextField source='ON_HOLD' label='On Hold' />
            <TextField source='TO_INGEST' label='To Ingest' />
            <TextField source='TO_TRANSFORM' label='To Transform' />
            <TextField source='FAILED' label='Failed' />
            <TextField source='PENDING' label='Pending' />
            <TextField source='FAILED_PUSH' label='Failed Push' />
         </EditableDatagrid>
      </List>
   )
}
