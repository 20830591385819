import { Card } from '@mui/material'

const Dashboard = () => {
   return (
      <Card>
         <h1>Dashboard</h1>
      </Card>
   )
}
export default Dashboard
