type Config = {
   CLIENT_APP_ID: string
   TENANT_ID: string
   API_URL: string
   WEB_URL: string
   SCOPES: string[]
}

type ConfigWrapper = {
   AppConfig: Config
}

export function getConfig(): Config {
   const appConfig = (window as Window & typeof globalThis & ConfigWrapper).AppConfig
   return appConfig
}
