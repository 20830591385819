import { CardContent } from '@mui/material/'
import Grid from '@mui/material/Grid'
import { useLogin, useNotify } from 'ra-core'
import { Login as RaLogin } from 'react-admin'
import { MicrosoftLoginButton } from './LoginButton'
import Logo from './Fintech-Logo.svg'

export const Login = () => {
   const login = useLogin()
   const notify = useNotify()
   const handleSubmit = () => {
      login({}).catch(() => notify('Invalid user'))
   }

   return (
      <RaLogin>
         <CardContent>
            <Grid container justifyContent='center' alignItems='center' direction='column'>
               <div className='jss3'>
                  <img src={Logo} alt={'logo'} />
               </div>
               <h2>Infosource</h2>
               <MicrosoftLoginButton buttonTheme='dark' onClick={handleSubmit} />
            </Grid>
         </CardContent>
      </RaLogin>
   )
}
