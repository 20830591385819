import { AppMenu } from './AppMenu'
import { AppLocationContext } from '@react-admin/ra-navigation'
// import { Layout as RaLayout, LayoutProps } from 'react-admin'
import { Layout as RaLayout, LayoutProps } from '@react-admin/ra-enterprise'
import { AppBar } from './AppBar'

export const Layout = (props: LayoutProps) => {
   return (
      <AppLocationContext>
         <RaLayout {...props} menu={AppMenu} appBar={AppBar} />
      </AppLocationContext>
   )
}
