import { useMsal } from '@azure/msal-react'
import { stringify } from 'query-string'
import { fetchUtils, DataProvider as RaDataProvider } from 'react-admin'
import { getConfig } from './utils'
import { loginRequest } from './AuthConfig'

export const DataProvider = (): RaDataProvider => {
   const config = getConfig()
   const apiUrl = config.API_URL
   const { instance } = useMsal()

   const httpClient = async (url: string, options: any = {}) => {
      const accounts = instance.getAllAccounts()
      const request = {
         scopes: loginRequest.scopes,
         account: accounts[0],
      }
      const authResult = await instance.acquireTokenSilent(request)
      const token = authResult.accessToken

      if (!options.headers) {
         options.headers = new Headers({ Accept: 'application/json' })
      }
      options.headers.set('Authorization', `Bearer ${token}`)
      return fetchUtils.fetchJson(url, options)
   }

   return {
      getList: async (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         const { page, perPage } = params.pagination
         const { field, order } = params.sort
         const rangeStart = (page - 1) * perPage
         const rangeEnd = page * perPage
         const { isPagination } = params.meta || { isPagination: true }
         const limit = isPagination ? { limit: JSON.stringify(perPage) } : {}
         const queryParams = {
            filter: JSON.stringify(params.filter),
            sort: JSON.stringify([field, order]),
            ...limit,
            offset: JSON.stringify(rangeStart),
            ...(params.meta || {}),
         }
         const options = {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
               Range: `${parsedResource}=${rangeStart}-${rangeEnd}`,
            }),
         }
         const url = `${apiUrl}/${parsedResource}?${stringify(queryParams)}`

         return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has('Content-Range')) {
               throw new Error(
                  `The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses
                    for lists of resources to contain this header with the total number of results to build the pagination. 
                    If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?`
               )
            }
            return {
               data: json,
               total: parseInt(headers.get('Content-Range')!.split('/').pop()!, 10),
            }
         })
      },

      getOne: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         const queryParams = {
            ...(params.meta || {}),
         }
         const url = `${apiUrl}/${parsedResource}/${params.id}?${stringify(queryParams)}`

         return httpClient(url).then(({ json }) => ({ data: json }))
      },

      getMany: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         const queryParams = {
            filter: JSON.stringify({ id: params.ids }),
         }
         const url = `${apiUrl}/${parsedResource}?${stringify(queryParams)}`
         return httpClient(url).then(({ json }) => ({ data: json }))
      },

      getManyReference: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         const { page, perPage } = params.pagination
         const { field, order } = params.sort

         const rangeStart = (page - 1) * perPage
         const rangeEnd = page * perPage - 1

         const queryParams = {
            filter: JSON.stringify({
               ...params.filter,
               [params.target]: params.id,
            }),
            sort: JSON.stringify([field, order]),
            limit: JSON.stringify(perPage),
            offset: JSON.stringify(rangeStart),
         }
         const url = `${apiUrl}/${parsedResource}?${stringify(queryParams)}`

         // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
         const options = {
            headers: new Headers({
               Range: `${parsedResource}=${rangeStart}-${rangeEnd}`,
            }),
         }

         return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has('Content-Range')) {
               throw new Error(
                  `The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses
                    for lists of resources to contain this header with the total number of results to build the pagination. 
                    If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?`
               )
            }
            return {
               data: json,
               total: parseInt(headers.get('Content-Range')!.split('/').pop()!, 10),
            }
         })
      },

      update: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         delete params.data.id // Remove id from body
         return httpClient(`${apiUrl}/${parsedResource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
         }).then(({ json }) => ({ data: json }))
      },

      // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
      updateMany: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         return Promise.all(
            params.ids.map((id) => {
               delete params.data.id // Remove id from body
               return httpClient(`${apiUrl}/${parsedResource}/${id}`, {
                  method: 'PUT',
                  body: JSON.stringify(params.data),
               })
            })
         ).then((responses) => ({ data: responses.map(({ json }) => json.id) }))
      },

      create: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         return httpClient(`${apiUrl}/${parsedResource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
         }).then(({ json }) => ({ data: json }))
      },

      delete: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')

         return httpClient(`${apiUrl}/${parsedResource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
               'Content-Type': 'text/plain',
            }),
         }).then(({ json }) => ({ data: json }))
      },

      // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
      deleteMany: (resource, params) => {
         const parsedResource = resource.replace('settings/', '')
         return Promise.all(
            params.ids.map((id) =>
               httpClient(
                  params.meta.queryParams
                     ? `${apiUrl}/${parsedResource}/${id}?${stringify(params.meta.queryParams)}`
                     : `${apiUrl}/${parsedResource}/${id}`,
                  {
                     method: 'DELETE',
                     headers: new Headers({
                        'Content-Type': 'text/plain',
                     }),
                  }
               )
            )
         ).then((responses) => ({
            data: responses.map(({ json }) => json.id),
         }))
      },
   }
}
