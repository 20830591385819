import { LogLevel } from '@azure/msal-browser'
import { getConfig } from './utils'

const config = getConfig()

export const msalConfig = () => {
   return {
      auth: {
         clientId: config.CLIENT_APP_ID,
         authority: `https://login.microsoftonline.com/${config.TENANT_ID}`,
         redirectUri: config.WEB_URL,
      },
      cache: {
         cacheLocation: 'localStorage',
         storeAuthStateInCookie: false,
      },
      system: {
         loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: any) => {
               if (containsPii) {
                  return
               }
               switch (level) {
                  case LogLevel.Error:
                     console.error(message)
                     return
                  case LogLevel.Info:
                     console.info(message)
                     return
                  case LogLevel.Verbose:
                     console.debug(message)
                     return
                  case LogLevel.Warning:
                     console.warn(message)
                     return
               }
            },
         },
      },
   }
}

export const loginRequest = {
   scopes: config.SCOPES,
}
