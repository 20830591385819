/* eslint-disable camelcase */
import { DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Button, Datagrid, List, TextField } from 'react-admin'
import MuiDialog from '@mui/material/Dialog'

const COL = [
   'log_id',
   'cycle_id',
   'aud_load_key',
   'aud_step_type_id',
   'service_test_id',
   'cycle_test_id',
   'file_key',
   'status_id',
   'status',
   'row_count',
   'err_code',
   'msg',
   'err_row_count',
   'table_name',
   'tab_name',
   'threshold',
   'log_path',
   'log_file_name',
   'aud_action_id',
   'aud_action_notes',
   'create_date',
   'create_user_id',
   'update_date',
   'update_user_id',
   'etl_data_flow_id',
   'id',
]

const Colums = () => (
   <>
      {COL.slice(0, 5).map((col) => (
         <TextField source={col} key={col} />
      ))}
   </>
)
export const Audlog = ({ fileKey, toggleDialog }: any) => {
   return (
      <MuiDialog open={true} onClose={toggleDialog()} fullWidth maxWidth='xl'>
         <DialogTitle>Audlog</DialogTitle>
         <DialogContent>
            <List
               disableSyncWithLocation
               bulkActionButtons={false}
               resource='audlog'
               sort={{ field: 'log_id', order: 'ASC' }}
               filter={{ file_key: fileKey }}
            >
               <Datagrid bulkActionButtons={false}>
                  <TextField source='file_key' />
                  <TextField source='msg' />
               </Datagrid>
            </List>
         </DialogContent>
         <DialogActions>
            <Button onClick={toggleDialog()} label='Cancel'></Button>
            {/* <Button onClick={handleClose}>Update</Button> */}
         </DialogActions>
      </MuiDialog>
   )
}

export default Audlog
